export const LoadingWhiteSpinner = "/Assets/loading.gif";
export const PinkQoutesIcon = "/Assets/qoutesIcon.svg";
export const NewEndQoutes = "/Assets/newEndQoutes.svg";
export const NewStartQoute = "/Assets/newStartQoute.svg";
export const PinkArabicQoutesIcon = "/Assets/arabicQoutes.svg";
export const LeftArrow = "/Assets/leftArrow.svg";
export const RightArrow = "/Assets/rightArrow.svg";
export const NewLeftArrow = "/Assets/newLeftArrow.svg";
export const NewRightArrow = "/Assets/newRightArrow.svg";
export const RightIcon = "/Assets/rightIcon.svg";
export const WhatsAppIcon = "/Assets/whatsApp.svg";
export const ClipboardIcon = "/Assets/Clipboard.svg";
export const WhatsAppSmallIcon = "/Assets/whatsappSmallIcon.svg";
export const PinkBoxIcon = "/pinkBox.png";
export const WearThatLogo = "/Assets/WearThatLogo.svg";
export const WTGIF = "/Assets/wt.gif";
export const MenuIcon = "/Assets/hamburgerMenu.svg";
export const NewMenuIcon = "/Assets/sideDrawericon.svg";
export const CloseIcon = "/Assets/closeIcon.svg";
export const Home = "/Assets/home.svg";
export const infoIcon = "/Assets/infoIcon.svg";
export const InfoIcon2 = "/Assets/info_Icon.svg";
export const Aboutus = "/Assets/aboutus.svg";
export const GiftCard = "/Assets/giftCard.svg";
export const VideoIcon = "/Assets/VideoIcon.svg";
export const RightArrowIcon = "/Assets/RightArrow.svg";
export const ReferFriend = "/Assets/referFriend.svg";
export const AmbassadorIcon = "/Assets/ambassadorIcon.svg";
export const ReadApproved = "/Assets/ReadApproved.png";
export const InStudioStylingSession = "/Assets/inStudioStylingSession.svg";
export const CalendarIcon = "/Assets/calendar_icon.svg";
export const SuccessTick = "/Assets/successTick.svg";
export const SuccessTickPink = "/Assets/successTickPink.svg";
export const FailedIcon = "/Assets/failedIcon.svg";
export const ClockIcon = "/Assets/clockIcon.svg";
export const LanguageIcon = "/Assets/languageIcon.svg";
export const NewLanguageIcon = "/Assets/newLanguageIcon.svg";
export const NewNotificationIcon = "/Assets/NotificationIcon.svg";
export const CancelIcon = "/Assets/cancelIcon.svg";
export const Line = "/Assets/Line.svg";
export const PlusIcon = "/Assets/plusIcon.svg";
export const BinIcon = "/Assets/bin.svg";
export const PinIcon = "/Assets/pin.svg";
export const LeftQoutes = "/Assets/leftQoutes.svg";
export const RightQoutes = "/Assets/rightQoutes.svg";
export const ArabicStart = "/Assets/arabicStart.svg";
export const ArabicEnd = "/Assets/arabicEnd.svg";
export const ShopTheLookIcon = "/Assets/ShopTheLook.svg";
export const UpArrow = "/Assets/upArrow.svg";
export const DownArrow = "/Assets/downArrow.svg";
export const PlayButton = "/Assets/PlayButton.svg";
export const SearchIcon = "/Assets/Search.svg";
export const TrendingIcon = "/Assets/Trending.svg";
export const BigBellIcon = "/Assets/bigBell.svg";
export const TwitterIcon = "/Assets/twitter.svg";
export const InstagramIcon = "/Assets/instagram.svg";
export const FacebookIcon = "/Assets/facebook.svg";
export const PinterestIcon = "/Assets/pinterest.svg";
export const BlogsIcon = "/Assets/BlogsIcon.svg";
export const WTLogoEnglish = "/Assets/WTLogoEnglish.svg";
export const WTLogoArabic = "/Assets/WTLogoArabic.svg";
export const OrderABox = "/Assets/orderABox.svg";
export const OrderInQueue = "/Assets/orderInQueue.svg";
export const AccountSettings = "/Assets/accountSettings.svg";
export const DashboardFAQs = "/Assets/DashboardFAQs.svg";
export const Logout = "/Assets/Logout.svg";
export const MyBoxes = "/Assets/myBoxes.svg";
export const ReferralActivities = "/Assets/ReferralProgramHowItWorksIcons.svg";
export const YourPreferences = "/Assets/yourPreferences.svg";
export const Right = "/Assets/Right.svg";
export const RightPink = "/Assets/RightPink.svg";
export const BreadcrumbRightArrow = "/Assets/BreadcrumbRightArrow.svg";
export const ProfileSetupIcon = "/Assets/ProfileSetup.svg";
export const AddressDetailsIcon = "/Assets/addressDetails.svg";
export const ChangePasswordIcon = "/Assets/changePassword.svg";
export const PaymentDetailsIcon = "/Assets/paymentDetails.svg";
export const MastercardIcon = "/Assets/MasterCardIcon.svg";
export const VisaIcon = "/Assets/VisaCardIcon.svg";
export const AddIconCircle = "/Assets/AddIconCircle.svg";
export const VisaSmallIcon = "/Assets/VisaIcon.svg";
export const MasterSmallIcon = "/Assets/MasterIcon.svg";
export const DiscoverCardIcon = "/Assets/DiscoverIcon.svg";
export const DinersCardIcon = "/Assets/DinersInternationalIcon.svg";
export const AmericanExpressIcon = "/Assets/AmericanExpressIcon.svg";
export const JCBCardIcon = "/Assets/JCBIcon.svg";
export const DropdownArrowDown = "/Assets/PinkDownArrow.svg";
export const DropdownArrowUp = "/Assets/PinkUpArrow.svg";
export const Mute = "/Assets/Mute.svg";
export const Unmute = "/Assets/Unmute.svg";
export const MuteIcon = "/Assets/muteIcon.svg";
export const UnmuteIcon = "/Assets/unmuteIcon.svg";
export const playIcon = "/Assets/playIcon.svg";
export const pauseIcon = "/Assets/pauseIcon.svg";
export const HomeBannerPlaceHolder = "/Assets/HomeBannerPlaceHolder.webp";
export const CheckIcon = "/Assets/check.svg";
export const HolidayIcon = "/Assets/holiday.svg";
export const GoingOutIcon = "/Assets/goingOut.svg";
export const WorkIcon = "/Assets/work.svg";
export const EssentialsIcon = "/Assets/essentials.svg";
export const ProgressBall = "/Assets/ProgressDot.svg";
export const ProgressBallBlack = "/Assets/ProgressDotBlack.svg";
export const ProgressBallDarkGrey = "/Assets/ProgressDotDarkGrey.svg";
export const BoxScheduled = "/Assets/BoxScheduled.svg";
export const WT_Logo = "/Assets/Logo.png";
export const ConfirmationIcon = "/Assets/confirmationIcon.svg";
export const PreviousButtonSvg = "/Assets/PreviousButton.svg";
export const NextButtonSvg = "/Assets/NextButton.svg";
export const SampleImage = "/Assets/sampleImage.png";
export const CircleError = "/Assets/CircleError.svg";
export const TertiaryRightArrow = "/Assets/arrowRight.svg";
export const BlackSimpleArrow = "/Assets/RightBlackArrow.svg";
export const WearthatLoading = "/Assets/WearthatLoading.gif";
export const BlackHamburgerMenu = "/Assets/BlackHamburgerMenu.svg";
export const SerenaLogo = "/Assets/SerenaLogo.svg";
export const WhiteRightArrow = "/Assets/whiteRightArrow.svg";
export const BlackPlusIcon = "/Assets/blackPlusIcon.svg";
export const MinusIcon = "/Assets/minusIcon.svg";
export const Switch_404 = "/Assets/404-switch.png";
export const cartMinus = "/Assets/cartMinus.svg";
export const cartPlus = "/Assets/cartPlus.svg";
export const cartDemoImg = "/Assets/cartDemoImg.png";
export const StepOne = "/Assets/step1.svg";
export const StepTwo = "/Assets/step2.svg";
export const EditIcon = "/Assets/Edit-Icon.svg";
export const Cart = "/Assets/Cart.svg";
export const CartSmall = "/Assets/CartSmall.svg";
export const BlackCross = "/Assets/BlackCross.svg";
export const SmallPlus = "/Assets/Plus14x14.svg";
export const SmallMinus = "/Assets/Minus14x14.svg";
export const BlackBin = "/Assets/BlackBin.svg";
export const ArrowLeft = "/Assets/arrowLeft.svg";
export const MobileSizeChart = "/Assets/mobileSizeChart.svg";
export const SizeChart = "/Assets/sizeChart.svg";
export const TickInCircleGreen = "/Assets/TickInCircleGreen.svg";
export const CrossInCircleRed = "/Assets/CrossInCircleRed.svg";
export const WishListWhiteIcon = "/Assets/wishlist_white_24x24";
export const WishListBlackIcon = "/Assets/wishlist_black_24x24.svg";
export const EcommCart = "/Assets/cart_white_24x24.svg";
export const WhiteChevronDown = "/Assets/white_chevron_down.svg";
export const FilterIcon = "/Assets/Filter.svg";
export const FunnelIcon = "/Assets/Funnel.svg";
export const SearchIconBlack = "/Assets/SearchIcon.svg";
export const BlackCross_24x24 = "/Assets/BlackCross_24x24.svg";
export const ArrowDownPlaneEnds = "/Assets/arrowDownPlaneEnds.svg";
export const AccountAvatar = "/Assets/AccountAvatar.svg";
export const ShoppingBag = "/Assets/Shoppingbag.svg";
export const ShoppableSection = "/Assets/ShoppableSessions.svg";
export const ThumbsDownFocusedIcon = "/Assets/thumbsDownFocusedIcon.svg";
export const ThumbsDownIcon = "/Assets/thumbsDownIcon.svg";
export const ThumbsUpIcon = "/Assets/thumbsUpIcon.svg";
export const ThumbsUpFocusedIcon = "/Assets/thumbsUpFocusedIcon.svg";
export const DressStyleIcon = "/Assets/dressStyleIcon.svg";
export const ContectStepIcon = "/Assets/contectStepIcon.svg";
export const EmailVerification = "/Assets/emailVerification.svg";
export const VideoCameraIcon = "/Assets/videoCameraIcon.svg";
export const SaudiIcon = "/Assets/Saudi.svg";
export const UAEIcon = "/Assets/UAE.svg";
export const ReferralProgramStep1Icon = "/Assets/ReferralProgramStep1Icon.svg";
export const ReferralProgramStep2Icon = "/Assets/ReferralProgramStep2Icon.svg";
export const ReferralProgramStep3Icon = "/Assets/ReferralProgramStep3Icon.svg";
export const BigOrderABox = "/Assets/bigOrderABox.svg";
export const BigMyBoxes = "/Assets/bigMyBoxes.svg";
export const BigPreferences = "/Assets/bigPreferences.svg";
export const BigSettings = "/Assets/bigSettings.svg";
export const BigFAQs = "/Assets/bigFAQs.svg";
export const BigRewards = "/Assets/bigRewards.svg";
export const GoogleIcon = "/Assets/googleIcon.svg";
